<template lang="pug">
b-container.fluid
  nav-bar-filter-components
</template>
<script>
import NavBarFilterComponents from "./NavBarFilterComponents.vue";

export default {
  name: "NavBar",
  components: {
    NavBarFilterComponents,
  },
  data() {
    return {
      name: "MainFilter",
    };
  },
};
</script>
